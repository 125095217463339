

const firebaseConfig = {
  apiKey: "AIzaSyCU79dv0A9n3rdqm9R3mtAgHhlCU9OqRKc",
  authDomain: "e-transport-38a1f.firebaseapp.com",
  projectId: "e-transport-38a1f",
  storageBucket: "e-transport-38a1f.appspot.com",
  messagingSenderId: "626449031788",
  appId: "1:626449031788:web:c4bcf2c5c8624920bfee49",
  measurementId: "G-RPFWQXB8NJ",
};

export default firebaseConfig;
