import React from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { useIntl } from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/home' />
      {/*<MenuItem title='Layout Builder' to='/builder' />*/}

      <MenuInnerWithSub title='Maestros' to='/masters' menuPlacement='bottom-start' menuTrigger='click'>
        {/* Maestros */}
        <MenuItem to='/masters/agencies/list' title='Agencias' hasBullet={true} />
        <MenuItem to='/masters/users/list' title='Usuarios' hasBullet={true} />
      </MenuInnerWithSub>

    </>
  )
}
