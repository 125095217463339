import { AuthModel, UserModel } from './_models';
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../../../firebase.config"
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, where, collection, query, getDocs } from "firebase/firestore";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Server should return AuthModel
export async function login(email: string, password: string) {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <AuthModel>{
    api_token: userCredential.user.uid,
    refreshToken: userCredential.user.uid
  };
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {

  return sendPasswordResetEmail(auth, email)

}

export async function getUserByToken(idToken: string) {
  const docRef = query(collection(db, "Users"), where("auth.api_token", "==", idToken));
  //const docRef = doc(db, "Users"), where();
  //const data = (await getDoc(docRef)).data();
  const data: any[] | UserModel = [];
  // eslint-disable-next-line array-callback-return
  (await getDocs(docRef)).docs.map(doc => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    data.push(doc.data())
  })
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <UserModel>data[0]
}
