/*eslint-disable jsx-a11y/anchor-is-valid*/
import React from 'react'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'


const HomePage: FC = () => {
  /* useEffect(() => {
     // We have to show toolbar only for dashboard page
     document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
     return () => {
       document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
     }
   }, [])*/

  return (
    <>

    </>
  )
}

const HomeWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <HomePage />
    </>
  )
}

export { HomeWrapper }
