/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItemWithSubMain } from './AsideMenuItemWithSubMain'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {

  return (
    <>
      <AsideMenuItemWithSubMain
        to='/masters'
        title='Maestros'
        fontIcon='bi-file-text'
        bsTitle='Maestros'
      >
        <AsideMenuItemWithSub to='/masters' title='Maestros' hasBullet={true}>
          <AsideMenuItem
            to='/masters/agencies'
            title='Agencias'
            bsTitle='Agencias'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/masters/users'
            title='Usuarios'
            bsTitle='Usuarios'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSubMain>
    </>
  )
}
